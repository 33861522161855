import { EventClass } from './event_class'
import {
  Camera,
  CameraAccess,
  DataCaptureView,
  FrameSourceState,
  configure,
  DataCaptureContext,
} from 'scandit-web-datacapture-core'

import {
  BarcodeCapture,
  BarcodeCaptureSettings,
  Symbology,
  barcodeCaptureLoader,
} from 'scandit-web-datacapture-barcode'

export class ScanditScanner extends EventClass {
  async init(element) {
    await configure({
      licenseKey: window.SCANDIT_LICENSE_KEY,
      libraryLocation: new URL('library/engine/', window.location.origin).toString(),
      moduleLoaders: [barcodeCaptureLoader()],
    })
    const context = await DataCaptureContext.create()
    this.context = context
    await context.setFrameSource(Camera.default)
    const view = new DataCaptureView()
    view.connectToElement(element)
    await view.setContext(context)
    const camera = Camera.default
    const cameraSettings = BarcodeCapture.recommendedCameraSettings
    await camera.applySettings(cameraSettings)
    await context.setFrameSource(camera)
    const settings = new BarcodeCaptureSettings()
    settings.enableSymbologies(this.enabledSymbologies())
    settings.codeDuplicateFilter = 4000
    this.enabledSymbologies().forEach((symbology) => {
      settings.settingsForSymbology(symbology).isColorInvertedEnabled = true
    })
    const barcodeCapture = await BarcodeCapture.forContext(context, settings)
    await barcodeCapture.setEnabled(false)
    this.barcodeCapture = barcodeCapture
    await camera.switchToDesiredState(FrameSourceState.On)
    this.camera = camera
    await barcodeCapture.setEnabled(true)
    barcodeCapture.addListener({
      didScan: async (barcodeCapture, barcodeCaptureSession) => {
        const recognizedBarcodes = barcodeCaptureSession.newlyRecognizedBarcode
        if (recognizedBarcodes) {
          this.emit('scan', { data: recognizedBarcodes._data, symbology: recognizedBarcodes._symbology } )
        }
      },
    })
    return context
  }

  async turnOff() {
    this.camera.switchToDesiredState(FrameSourceState.Off)
    this.barcodeCapture.setEnabled(false)
  }

  async availableCameras() {
    const availableDeviceCameras = await CameraAccess.getCameras()
    return availableDeviceCameras
  }

  async setActiveCamera(camera) {
    camera = Camera.fromDeviceCamera(camera)
    this.camera.switchToDesiredState(FrameSourceState.Off)
    await this.context.setFrameSource(camera)
    await camera.switchToDesiredState(FrameSourceState.On)
    this.camera = camera
  }

  enabledSymbologies() {
    return [
      Symbology.Codabar,
      Symbology.Code11,
      Symbology.Code25,
      Symbology.Code32,
      Symbology.Code39,
      Symbology.Code93,
      Symbology.Code128,
      Symbology.DataMatrix,
      Symbology.EAN8,
      Symbology.EAN13UPCA,
      Symbology.MatrixTwoOfFive,
      Symbology.IATATwoOfFive,
      Symbology.InterleavedTwoOfFive,
      Symbology.PDF417,
      Symbology.QR,
      Symbology.RM4SCC,
      // Symbology.UPCE
    ]
  }
}
